.About-description {
    margin-top: 0px;
}

.Technical-skills p {
    background-color: aqua;
    width: 0rem;
}

h3 {
    text-decoration: underline;
}

.Technical-skills {
    margin-left: 20rem;
    line-height: unset;
}

@media screen and ((min-width:501px) and (max-width:767px)) {
    .Education-heading {
        margin-right: 4rem;
    }

    .Technical-skills {
        margin-left: 8rem;
        line-height: unset;
    }
}

@media screen and (max-width:500px) {
    .Technical-skills {
        margin-left: 6rem;
        line-height: unset;
    }
}