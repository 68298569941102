@media screen and (min-width:1200px) {
  .toggle-bar {
    display: none;
  }

  .nav-bar {
    position: fixed;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    left: 0px;
    right: 0px;
  }

  .menu-bar {
    display: flex;
    justify-content: center;
    font-weight: bolder;
    left: 0px;
    right: 0px;
    font-size: large;
    position: fixed;
    background-color: rgb(255, 255, 255);
  }

  .menu {
    padding: 1.5rem;
  }

  .menu-item {
    display: inline;
    padding: 1rem;
    border-radius: 100%;
    margin: 1rem;
  }

  .menu-item:hover {
    background-color: rgb(218, 209, 209);
    color: rgb(0, 0, 0);
    cursor: pointer;
  }

  .menu-bar a:link,
  .menu-bar a:visited {
    color: rgb(0, 0, 0);
    text-decoration: none;
    cursor: pointer;
  }

  .menu-bar a:hover {
    color: rgb(7, 8, 8);
    cursor: pointer;
  }

  .empty {
    width: 20px;
    height: 10px;
    background-color: aqua;
  }

}

@media screen and (max-width:360px) {
  .menu {
    display: none;
  }

  .toggle-bar {
    display: inline;
    margin-left: 3rem;
    font-size: small;
  }

  .nav-bar {
    position: fixed;
    background-color: rgb(255, 255, 255);
    left: 0px;
    right: 0px;
  }

  .menu-bar {
    margin-left: 1rem;
  }
}


@media screen and ((min-width:361px) and (max-width:500px)) {
  .menu {
    display: none;
  }

  .toggle-bar {
    display: inline;
    margin-left: 1rem;
  }
}

@media screen and ((min-width:501px) and (max-width:767px)) {
  .menu {
    display: none;
  }

  .toggle-bar {
    display: inline;
    margin-left: 3rem;
  }

}


@media screen and ((min-width:768px)and (max-width:1199px)) {
  .toggle-bar {
    display: none;
  }

  .nav-bar {
    position: fixed;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    left: 0px;
    right: 0px;
  }

  .menu-bar {
    display: flex;
    justify-content: center;
    font-weight: bolder;
    left: 0px;
    right: 0px;
    font-size: medium;
    position: fixed;
    background-color: rgb(255, 255, 255);
  }

  .menu {
    margin-top: 1.8rem;
    padding: 0.5rem;
  }

  .menu-item {
    display: inline;
    padding: 0.4rem;
    border-radius: 100%;
    margin: 0.5rem;
  }

  .menu-item:hover {
    background-color: rgb(218, 209, 209);
    color: rgb(0, 0, 0);
    cursor: pointer;
  }

  .menu-bar a:link,
  .menu-bar a:visited {
    color: rgb(0, 0, 0);
    text-decoration: none;
    cursor: pointer;
  }

  .menu-bar a:hover {
    color: rgb(7, 8, 8);
    cursor: pointer;
  }

  .empty {
    width: 15px;
    height: 7px;
    background-color: aqua;
  }

}