.Education-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.Education .self-description img {
    border-radius: 10px;
    height: 500px;
    width: 500px;


}

.About {
    margin-top: 6rem;
    font-size: medium;
    padding: 8px;
    word-spacing: 5px;


}

.self-description .right-image {
    width: 500px;
    height: 400px;
}

h2 {
    font-size: 2.5rem;
}