.name {
    font-family: Babylonica, cursive;
    color: rgb(16, 17, 16);
    /* font-size: 10rem; */
    display: inline;
}

.signature {
    display: inline;
    font-size: 5rem;
}

a {
    text-decoration: none;
}

.name a:link,
.name a:visited {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.name a:hover {
    color: rgb(7, 8, 8);
}


@media screen and (max-width:360px) {
    .name-style {
        display: inline;
    }

    .signature {
        font-size: 2rem;
    }

    .arrow {
        font-size: unset;
        font-size: 1rem;
    }

}

@media screen and ((min-width:361px) and (max-width:500px)) {
    .name-style {
        display: inline;
        background-color: red;
        margin-right: 1rem;
    }

    .signature {
        font-size: 3.5rem;
    }

}

@media screen and ((min-width:501px) and (max-width:767px)) {
    .name-style {
        display: inline;
        background-color: aqua;
        margin-left: 1rem;
    }

    .signature {
        font-size: 4.5rem;
    }

}

@media screen and ((min-width:768px)and (max-width:1199px)) {
    .name-style {
        margin: 0px;

    }

    .signature {
        font-size: 3.8rem;
    }
}