@media screen and (min-width:1200px) {
    .Home-developer {
        display: block;
        overflow: hidden;
    }

    .developer {
        margin-left: 60rem;
        padding-top: 12rem;
        text-align: center;
        margin-bottom: 5rem;
    }

    .discription {
        text-shadow: 1px 1px black;
        letter-spacing: 2px;
        font-size: xx-large;
        overflow: hidden;
    }

    h4 {
        padding: 2px 3px 2px 3px;

    }

    img {
        width: 1000px;
        margin-left: -60px;
        display: inline;
        float: left;
    }

    .image {
        display: inline;
    }

    .self-description {
        clear: both;
        margin-top: 20px;
        overflow: hidden;
    }



    .self-description .right-image {
        float: right;
        overflow: hidden;
        height: 1000px;
        width: 1000px;
    }

    .About {
        margin-top: 13rem;
        padding-left: 10px;
        padding-right: 10px;
        overflow: hidden;
    }

    .About-description {
        margin-top: 6rem;
        padding: 20px;
        text-align: center;
        font-size: large;
        font-weight: bold;
        word-spacing: 5px;
        overflow: hidden;
    }

}

@media screen and ((min-width:1040px)and (max-width:1199px)) {
    .Home-developer {
        display: block;
        overflow: hidden;

    }

    .developer {
        margin-left: 40rem;
        padding-top: 10rem;
        text-align: center;
        margin-bottom: 5rem;
    }

    .discription {
        text-shadow: 1px 1px black;
        letter-spacing: 2px;
        font-size: xx-large;
        overflow: hidden;
    }

    h4 {
        padding: 2px 3px 2px 3px;

    }

    img {
        width: 800px;
        margin-left: -70px;
        display: inline;
        float: left;
    }

    .image {
        display: inline;
    }

    .self-description {
        clear: both;
        margin-top: 20px;
        overflow: hidden;
    }



    .self-description .right-image {
        float: right;
        overflow: hidden;
        height: 1000px;
        width: 1000px;
    }

    .About {
        margin-top: 13rem;
        padding-left: 10px;
        padding-right: 10px;
        overflow: hidden;
    }

    .About-description {
        margin-top: 6rem;
        padding: 20px;
        text-align: center;
        font-size: large;
        font-weight: bold;
        word-spacing: 5px;
        overflow: hidden;
    }

}

@media screen and ((min-width:767px)and (max-width:1039px)) {
    .Home-developer {
        display: block;
        overflow: hidden;

    }

    .developer {
        text-align: center;
        margin-bottom: 5rem;
    }

    .discription {
        text-shadow: 1px 1px black;
        letter-spacing: 2px;
        font-size: xx-large;
        overflow: hidden;
    }

    h4 {
        padding: 2px 3px 2px 3px;

    }

    img {
        width: 800px;
        /* margin-left: -100px; */
    }

    .self-description {
        clear: both;
        margin-top: 20px;
        overflow: hidden;
    }

    .self-description .right-image {
        margin-left: 12rem;
        overflow: hidden;
        height: 1000px;
        width: 1000px;
    }

    .About {
        margin-top: 13rem;
        overflow: hidden;
    }

    .About-description {
        margin-top: 6rem;
        padding: 20px;
        text-align: center;
        font-size: large;
        font-weight: bold;
        word-spacing: 5px;
        overflow: hidden;
    }

}

@media screen and ((min-width:501px) and (max-width:767px)) {
    .developer {
        margin-right: 12rem;
        text-align: center;
    }


    .discription {
        text-shadow: 1px 1px black;
        letter-spacing: 2px;
        font-size: xx-large;
        overflow: hidden;
    }

    h4 {
        padding: 2px 3px 2px 3px;
    }



    .About {
        margin-right: 6rem;
    }

    .self-description .right-image {
        overflow: hidden;
        height: 1000px;
        width: 1000px;
    }

    .About-description {
        padding: 2px;
        text-align: center;
        font-size: large;
        font-weight: bold;
        word-spacing: 5px;
        overflow: hidden;
        margin-right: 5rem;
    }
}

@media screen and ((min-width:361px) and (max-width:500px)) {
    .developer {
        margin-right: 21rem;
        overflow: hidden;
    }

    .developer h2 {
        padding-left: 2px;
        overflow: hidden;
    }

    .discription {
        text-shadow: 1px 1px black;
        letter-spacing: 1px;
        padding: 2px;
        font-size: large;
        overflow: hidden;
    }

    h4 {
        padding: 2px 3px 2px 3px;
    }

    .About {
        margin-right: 6rem;
    }

    .img {
        margin-left: -15px;
    }

    .self-description .right-image {
        overflow: hidden;
        height: 1000px;
        width: 1000px;
    }

    .About-description {
        padding: 2px;
        text-align: center;
        font-size: large;
        font-weight: bold;
        word-spacing: 5px;
        overflow: hidden;
        margin-right: 5rem;
    }

}