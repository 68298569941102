@media screen and ((min-width:501px) and (max-width:767px)) {
    img {
        width: 550px;
        margin-left: -20px;
        padding-top: 0px;
    }

}

@media screen and (max-width:500px) {
    img {
        width: 450px;
        margin-left: -20px;
        padding-top: 0px;
    }
}